@font-face {
  font-family: 'Montserrat';
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
  font-weight: normal;
  font-style: normal;
}

:root{
  --primary-color: #202020;
  --secondary-color: white;
  --terciary-color: #3c3c3c;
  --primary-text-color: rgb(0, 0, 0);
  --secondary-text-color: gray;
  --terciary-text-color: #f0f0f0;
  --hover-color: #5d5d5d;
  --user-message-container: #ccf9ea;
  --bot-message-container: #fef0d7;
  --user-text-color: #8b685c;
  --bot-text-color: #8b685c;
  --drawer-background-color: #ddf7e0;
  --drawer-buttons-color: #ffffff;
  --drawer-buttons-color-hover: #e7fde9;
  --new-chat-button-background-color: #f6c553;
  --new-chat-button-background-color-hover: #d39102c7;
  --new-chat-button-color: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.drawer-component {
  background-color: var(--drawer-background-color);
  color: var(--primary-text-color);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.15);
}

.chat-search > input {
  background-color: var(--drawer-buttons-color);
  color: black;
  padding-left: 5%;
  border: none;
}

.chat-search > input::placeholder {
  color: var(--secondary-text-color);
}

.chat-search > input:focus {
  outline: none;
}

.chat-button {
  width: 100%;
  border-radius: 6px;
  border: none;
  height: auto;
  background-color: var(--drawer-buttons-color);
  color: var(--primary-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.5rem;
  cursor: pointer;
}

.chat-button:hover {
  background-color: var(--drawer-buttons-color-hover);
}

.model-badge {
  background-color: var(--new-chat-button-background-color);
  border-radius: 2rem;
  padding: 0.3rem 0.5rem;
  color: var(--secondary-color);
}

.new-chat {
  background-color: rgba(255, 168, 6, 0.6);
}

.new-chat:hover {
  background-color: rgba(255, 168, 6, 0.3);
}

.drawer-button {
  width: 30px;
  height: 30px;
  color: 'black';
  cursor: pointer;
  margin-left: 1rem;
}

.drawer-button:hover {
  color: var(--hover-color);
}

.chat-input {
  width: 50%;
  height: 3rem;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  border: none;
  padding-left: 5%;
  border-radius: 1rem;
}

.chat-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 1rem;
  width: 90%;
  padding: 1rem;
  overflow-y: auto;
}

/* Add these CSS animations */
@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient {
  background-size: 200% 200%;
  animation: gradientFlow 3s ease infinite;
}